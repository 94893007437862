'use strict';
import initVh from './modules/initVh';
import initSmoothAnchor from './modules/initSmoothAnchor';
import {toggleModal} from './modules/toggleModal';
import submitForm from './modules/submitForm';
import toggleServicesItem from './modules/toggleServicesItem';
import make4thMenuItemHidden from './modules/make4thMenuItemHidden';
import showMoreMenuItems from './modules/showMoreMenuItems';
import initSlider from './modules/initSlider';
import initDragSlider from './modules/initDragSlider';

window.addEventListener('DOMContentLoaded', () => {
    initVh();
    
    initSmoothAnchor({
        anchorsParentSelector: '.header__list',
        anchorHeaderSelector: '.header__list-link',
        anchor: '.anchor',
        modalSelector: null, 
        modalActiveClass: null, 
        closeBtnClass: null, 
        closeOnClickOnBg: false, 
        automatically: false
    });

    initSmoothAnchor({
        anchorsParentSelector: '.header__list',
        anchorHeaderSelector: '.header__list-link',
        anchor: '.anchor',
        modalSelector: '.header__nav', 
        modalActiveClass: 'header__nav_active', 
        closeBtnClass: null, 
        closeOnClickOnBg: false, 
        automatically: true
    });

    initSmoothAnchor({
        anchorsParentSelector: '.hamburger-content .header__list',
        anchorHeaderSelector: '.hamburger-content .header__list-link',
        anchor: '.anchor',
        modalSelector: '.hamburger-content', 
        modalActiveClass: 'hamburger-content_active', 
        closeBtnClass: null, 
        closeOnClickOnBg: false, 
        automatically: true
    });

    toggleModal({
        modalSelector: '.header__callback-wrapper .callback__form_main',
        modalActiveClass: 'callback__form_active',
        modalBtnSelector: '.header__callback_1920',
        closeBtnClass: 'callback__form-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    });

    submitForm({
        formSelector: '.header .callback__form',
        inputTextSelector: '.header .callback__form input[type="text"]',
        inputPhoneSelector: '.header .callback__form input[type="tel"]',
        inputDateSelector: null,
        modalMainSelector: '.header .callback__form_main',
        modalThanksSelector: '.header .callback__form_thanks',
        modalErrorSelector: '.header .callback__form_error',
        modalActiveClass: 'callback__form_active',
        closeBtnClass: 'callback__form-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        spinnerSelector: '.header .spinner-wrapper',
        contentSelector: '.header .callback__form-wrapper',
        displayPropertyForContent: 'flex',
        url: 'mailer/callback.php'
    });

    // модальное окно с навигацией на разрешении от 991px до 576px
    toggleModal({
        modalSelector: '.header .header__nav',
        modalActiveClass: 'header__nav_active',
        modalBtnSelector: '.header__hamburger_992',
        closeBtnClass: 'header__nav-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    });

    // модальное окно бургера в хедере на разрешении от 576px до 320px
    toggleModal({
        modalSelector: '.hamburger-content',
        modalActiveClass: 'hamburger-content_active',
        modalBtnSelector: '.header__hamburger_320',
        closeBtnClass: 'header__nav-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    });

    // модальное окно формы, которая находится в бургере, на разрешении от 576px до 320px. действует как для хедера, так и для футера, потому что окно практически одно и то же
    toggleModal({
        modalSelector: '.header .callback__form_main',
        modalActiveClass: 'callback__form_active',
        modalBtnSelector: '.header__callback_320',
        closeBtnClass: 'callback__form-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    });

    // модальное окно в promo
    toggleModal({
        modalSelector: '.modal_order',
        modalActiveClass: 'modal_active',
        modalBtnSelector: '.promo__order',
        closeBtnClass: 'modal__close',
        closeOnClickOnBg: true,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    });

    submitForm({
        formSelector: '.promo .modal__form',
        inputTextSelector: '.promo .modal__form input[type="text"]',
        inputPhoneSelector: '.promo .modal__form input[type="tel"]',
        inputDateSelector: '.promo .modal__form input[type="date"]',
        modalMainSelector: '.promo .modal_order',
        modalThanksSelector: '.promo .modal_thanks',
        modalErrorSelector: '.promo .modal_error',
        modalActiveClass: 'modal_active',
        closeBtnClass: 'modal__close',
        closeOnClickOnBg: true,
        overflow: 'hidden',
        hasScroll: true,
        spinnerSelector: '.promo .spinner-wrapper',
        contentSelector: '.promo .modal__form',
        displayPropertyForContent: 'flex',
        url: 'mailer/order.php'
    });

    toggleServicesItem({
        servicesParentSelector: '.services__list',
        serviceSelector: '.services__item',
        serviceActiveClass: 'services__item_active',
        serviceItemButtonSelector: '.services__item-btn',
        closeButtonSelector: '.services__form-close'
    });

    submitForm({
        formSelector: '.services_services .services__form_main',
        inputTextSelector: '.services_services .services__form_main input[type="text"]',
        inputPhoneSelector: '.services_services .services__form_main input[type="tel"]',
        inputDateSelector: '.services_services .services__form_main input[type="date"]',
        modalMainSelector: '.services_services .services__form_main',
        modalThanksSelector: '.services_services .services__form_thanks',
        modalErrorSelector: '.services_services .services__form_error',
        modalActiveClass: 'services__form_active',
        closeBtnClass: 'services__form-close',
        closeOnClickOnBg: false,
        overflow: '',
        hasScroll: false,
        spinnerSelector: '.services_services .spinner-wrapper',
        contentSelector: '.services_services .services__form-wrapper',
        displayPropertyForContent: 'flex',
        url: 'mailer/services.php'
    });

    submitForm({
        formSelector: '.services_shashlik-pilaw .services__form_main',
        inputTextSelector: '.services_shashlik-pilaw .services__form_main input[type="text"]',
        inputPhoneSelector: '.services_shashlik-pilaw .services__form_main input[type="tel"]',
        inputDateSelector: '.services_shashlik-pilaw .services__form_main input[type="date"]',
        modalMainSelector: '.services_shashlik-pilaw .services__form_main',
        modalThanksSelector: '.services_shashlik-pilaw .services__form_thanks',
        modalErrorSelector: '.services_shashlik-pilaw .services__form_error',
        modalActiveClass: 'services__form_active',
        closeBtnClass: 'services__form-close',
        closeOnClickOnBg: false,
        overflow: '',
        hasScroll: false,
        spinnerSelector: '.services_shashlik-pilaw .spinner-wrapper',
        contentSelector: '.services_shashlik-pilaw .services__form-wrapper',
        displayPropertyForContent: 'flex',
        url: 'mailer/shashlik-pilaw.php'
    });

    submitForm({
        formSelector: '.services_delivery .services__form_main',
        inputTextSelector: '.services_delivery .services__form_main input[type="text"]',
        inputPhoneSelector: '.services_delivery .services__form_main input[type="tel"]',
        inputDateSelector: '.services_delivery .services__form_main input[type="date"]',
        modalMainSelector: '.services_delivery .services__form_main',
        modalThanksSelector: '.services_delivery .services__form_thanks',
        modalErrorSelector: '.services_delivery .services__form_error',
        modalActiveClass: 'services__form_active',
        closeBtnClass: 'services__form-close',
        closeOnClickOnBg: false,
        overflow: '',
        hasScroll: false,
        spinnerSelector: '.services_delivery .spinner-wrapper',
        contentSelector: '.services_delivery .services__form-wrapper',
        displayPropertyForContent: 'flex',
        url: 'mailer/delivery.php'
    });

    make4thMenuItemHidden({
        menuItemSelector: '.menu__item:nth-child(4)',
        menuItemHiddenClass: 'menu__item_hidden'
    });

    showMoreMenuItems({
        btnSelector: '.menu__btn',
        btnWrapperSelector: '.menu__btn-wrapper',
        menuItemSelector: '.menu__item_hidden',
        menuItemHiddenClass: 'menu__item_hidden'
    });

    // два вызова initSlider() ниже очень хотят рефакторинга, но у меня не было времени
    initSlider({
        slide: '.feedback .slider__wrapper_1920 .slider__item_1920',
        prevArrow: '.feedback .slider__wrapper_1920 .slider__arrow-left', 
        nextArrow: '.feedback .slider__wrapper_1920 .slider__arrow-right', 
        wrapper: '.feedback .slider__wrapper_1920',
        field: '.feedback .slider__wrapper_1920 .slider',
        automatically: false
    });

    initSlider({
        slide: '.feedback .slider__wrapper_576 .slider__item_576',
        prevArrow: '.feedback .slider__wrapper_576 .slider__arrow-left', 
        nextArrow: '.feedback .slider__wrapper_576 .slider__arrow-right', 
        wrapper: '.feedback .slider__wrapper_576',
        field: '.feedback .slider__wrapper_576 .slider',
        automatically: false
    });

    // initDragSlider();

    submitForm({
        formSelector: '.ready .ready__form_main',
        inputTextSelector: '.ready .ready__form_main input[type="text"]',
        inputPhoneSelector: '.ready .ready__form_main input[type="tel"]',
        inputDateSelector: '.ready .ready__form_main input[type="date"]',
        modalMainSelector: '.ready .ready__form_main',
        modalThanksSelector: '.ready .ready__form_thanks',
        modalErrorSelector: '.ready .ready__form_error',
        modalActiveClass: 'ready__form_active',
        closeBtnClass: 'ready__form-close',
        closeOnClickOnBg: false,
        overflow: '',
        hasScroll: false,
        spinnerSelector: '.ready .spinner-wrapper',
        contentSelector: '.ready__form-wrapper',
        displayPropertyForContent: 'flex',
        url: 'mailer/order.php'
    });

    toggleModal({
        modalSelector: '.footer__callback-wrapper .callback__form',
        modalActiveClass: 'callback__form_active',
        modalBtnSelector: '.footer__callback',
        closeBtnClass: 'callback__form-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    });

    submitForm({
        formSelector: '.footer .callback__form_main',
        inputTextSelector: '.footer .callback__form input[type="text"]',
        inputPhoneSelector: '.footer .callback__form input[type="tel"]',
        inputDateSelector: null,
        modalMainSelector: '.footer .callback__form_main',
        modalThanksSelector: '.footer .callback__form_thanks',
        modalErrorSelector: '.footer .callback__form_error',
        modalActiveClass: 'callback__form_active',
        closeBtnClass: 'callback__form-close',
        closeOnClickOnBg: false,
        overflow: '',
        hasScroll: false,
        spinnerSelector: '.footer .spinner-wrapper',
        contentSelector: '.footer .callback__form-wrapper',
        displayPropertyForContent: 'flex',
        url: 'mailer/callback.php'
    });

    // модальное окно бургера в футере на разрешении от 576px до 320px
    toggleModal({
        modalSelector: '.hamburger-content',
        modalActiveClass: 'hamburger-content_active',
        modalBtnSelector: '.footer__hamburger',
        closeBtnClass: 'header__nav-close',
        closeOnClickOnBg: false,
        overflow: 'hidden',
        hasScroll: true,
        automatically: false
    }); 

    initSmoothAnchor({
        anchorsParentSelector: '.footer__list',
        anchorHeaderSelector: '.footer__list-link',
        anchor: '.anchor',
        modalSelector: null, 
        modalActiveClass: null, 
        closeBtnClass: null, 
        closeOnClickOnBg: false, 
        automatically: false
    });
});




