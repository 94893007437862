function showMoreMenuItems({
    btnSelector,
    btnWrapperSelector,
    menuItemSelector,
    menuItemHiddenClass
    }) {
    const btn = document.querySelector(btnSelector);
    const btnWrapper = document.querySelector(btnWrapperSelector);
    const menuItems = document.querySelectorAll(menuItemSelector);

    btn.addEventListener('click', () => {
        menuItems.forEach(menuItem => {
            menuItem.classList.remove(menuItemHiddenClass);
        })

        btnWrapper.style.display = 'none';
    })
}

export default showMoreMenuItems;