// на разрешении 1920px до 992px в блоке меню отображаются 4 элемента. ниже 991px - 3 элемента 
// эта функция скрывает лишний четвертый элемент
function make4thMenuItemHidden({
    menuItemSelector,
    menuItemHiddenClass
    }) {
    const menuItem = document.querySelector(menuItemSelector);

    function init() {
        if (document.documentElement.clientWidth < 1200) {
            menuItem.classList.add(menuItemHiddenClass);
        }
    
        if (document.documentElement.clientWidth >= 1200) {
            menuItem.classList.remove(menuItemHiddenClass);
        }
    }

    init();
    window.addEventListener('resize', init);  
}

export default make4thMenuItemHidden;