import hideSpinner from './hideSpinner';
import showSpinner from './showSpinner';
import {openModal, closeModal} from './toggleModal';

// closeBtnClass - крестик для закрытия формы/модального окна
// overflow - если true, то страницу нельзя скроллить, пока модальное окно/форма открыта
// hasScroll - если true, то при открытии модального окна/формы к body добавляется справа отступ, чтобы не было дергания экрана
// contentSelector - тот контент, который скрывается, пока крутится спиннер, и наоборот 
// displayPropertyForContent - когда контент показан, то добавляет ему или grid, или flex
function submitForm({
    formSelector, 
    inputTextSelector, 
    inputPhoneSelector, 
    inputDateSelector, 
    modalMainSelector, 
    modalThanksSelector, 
    modalErrorSelector, 
    modalActiveClass,
    closeBtnClass,
    closeOnClickOnBg,
    overflow,
    hasScroll,
    spinnerSelector,
    contentSelector,
    displayPropertyForContent,
    url
    }) {
    const form = document.querySelector(formSelector),
          inputTexts = form.querySelectorAll(inputTextSelector),
          inputPhone = form.querySelector(inputPhoneSelector),
          inputDate = form.querySelector(inputDateSelector);
    
    const postData = async (url, data) => {
        let result = await fetch(url, {
            method: "POST",
            body: data
        });

        return await result.text();
    };

    const clear = () => {
        inputTexts.forEach(input => {
            input.value = '';
        });

        if (inputPhone) {
            inputPhone.value = '';
        }

        if (inputDate) {
            inputDate.value = '';
        }
    };

    form.addEventListener('submit', function(e) {
        e.preventDefault();
        showSpinner(spinnerSelector, contentSelector);
        
        const formData = new FormData(form);
        postData(url, formData)
            .then(() => {
                hideSpinner(spinnerSelector, contentSelector, displayPropertyForContent);
                closeModal({
                    modalSelector: modalMainSelector, 
                    modalActiveClass, 
                    closeBtnClass, 
                    closeOnClickOnBg, 
                    automatically: true
                });
                openModal({
                    modalSelector: modalThanksSelector, 
                    modalActiveClass,
                    overflow,
                    hasScroll
                });
                closeModal({
                    modalSelector: modalThanksSelector, 
                    modalActiveClass, 
                    closeBtnClass, 
                    closeOnClickOnBg, 
                    automatically: false
                });
            })
            .catch(() => {
                hideSpinner(spinnerSelector, contentSelector, displayPropertyForContent);
                closeModal({
                    modalSelector: modalMainSelector, 
                    modalActiveClass, 
                    closeBtnClass, 
                    closeOnClickOnBg, 
                    automatically: true
                });
                openModal({
                    modalSelector: modalErrorSelector, 
                    modalActiveClass,
                    overflow,
                    hasScroll
                });
                closeModal({
                    modalSelector: modalErrorSelector, 
                    modalActiveClass, 
                    closeBtnClass, 
                    closeOnClickOnBg, 
                    automatically: false
                });
            })
            .finally(() => {
                clear();
            });
    });
};

export default submitForm;