// функция для плавного скролла к якорю
import {closeModal} from './toggleModal';

function initSmoothAnchor({
    anchorsParentSelector, 
    anchorHeaderSelector,
    anchor,
    modalSelector, 
    modalActiveClass, 
    closeBtnClass, 
    closeOnClickOnBg, 
    automatically
    }) {
    const anchorsParent = document.querySelector(anchorsParentSelector);
    const anchorHeaders = document.querySelectorAll(anchorHeaderSelector);
    const anchors = document.querySelectorAll(anchor);

    anchorsParent.addEventListener('click', e => {
        if (e.target && e.target.closest(anchorHeaderSelector)) {
            e.preventDefault();

            anchorHeaders.forEach((anchorHeader, i) => {
                if (e.target === anchorHeader || e.target.closest((anchorHeaderSelector) === anchorHeader)) {
                    e.preventDefault();
                    
                    window.scroll({
                        left: 0,
                        top: anchors[i].offsetTop - document.querySelector('.header').clientHeight,
                        behavior: 'smooth'
                    });

                    if (modalSelector) {
                        closeModal({
                            modalSelector, 
                            modalActiveClass, 
                            closeBtnClass, 
                            closeOnClickOnBg, 
                            automatically
                        });
                    }
                }
            })
        }
    })
}

export default initSmoothAnchor;