function toggleServicesItem({
    servicesParentSelector,
    serviceSelector,
    serviceActiveClass,
    serviceItemButtonSelector,
    closeButtonSelector
    }) {
    const servicesParent = document.querySelector(servicesParentSelector);
    const services = document.querySelectorAll(serviceSelector);
    const buttons = document.querySelectorAll(serviceItemButtonSelector);

    servicesParent.addEventListener('click', e => {
        if (e.target && e.target.closest((serviceItemButtonSelector))) {
            buttons.forEach((button, i) => {
                if (e.target === button || e.target.closest((serviceItemButtonSelector) === button)) {
                    services.forEach(service => {
                        service.classList.remove(serviceActiveClass);
                    });

                    services[i].classList.add(serviceActiveClass);

                    // разворот карточки обратно при клике на крестик
                    services[i].addEventListener('click', e => { 
                        if (e.target.closest(closeButtonSelector)) {
                            services[i].classList.remove(serviceActiveClass);
                        }
                    });

                    // разворот карточки обратно при клике в любое место вне карточки
                    window.addEventListener('click', e => {
                        if (!e.target.closest(serviceSelector)) {
                            services[i].classList.remove(serviceActiveClass);
                        }
                    });
                }
            });
        }
    });
}

export default toggleServicesItem;